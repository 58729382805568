import React from "react";
import Image from "@sparefoot/react-commons/Image";
import { H1 } from "components/core/Typography";
import { SearchBar } from "components/search/SearchBar";
import HeroIllustration from "assets/images/hero-illustration.svg";

import "./Hero.scss";

const Hero = () => (
	<div className="hero">
		<div className="hero__inner">
			<div className="hero__content">
				<Image
					alt="Illustration of storage rooms"
					className="hero__illustration"
					src={HeroIllustration}
					lazy={false}
				/>
				<div className="hero__search-bar">
					<SearchBar
						buttonText="Search for Units"
						id="hero-search-bar"
						label="Search by city, state, or zip"
						theme="white"
						segmentCategory="Hero-SearchBar"
					>
						<H1
							weight="bold"
							size="xxlarge"
							largeSize="large"
							align="center"
						>
							Find Storage Units Near You
						</H1>
					</SearchBar>
				</div>
			</div>
		</div>
	</div>
);

export default Hero;
