/* eslint-disable max-len */
import { getIndexViewPixel } from "@sparefoot/react-commons/utils/tracking";
import { mergeUrlQueryParams } from "@sparefoot/react-commons/utils/url";

export const homePageSelector = ({ app }) => ({
	trackingPixelUrl: mergeUrlQueryParams(
		`${app.trackingUrl}${getIndexViewPixel()}`,
		{ a: app.trackingId }
	),
	meta: {
		title: "Find & Rent Self Storage Units Near You - Storage.com",
		description:
			"Storage.com is a self-storage marketplace where you can easily browse an compare all storage facilities and units in your area. Reserve online for free with no credit card required.",
		canonical: app.baseUrl
	}
});

export default homePageSelector;
