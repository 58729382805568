import React from "react";
import { useSelector } from "react-redux";
import { PageTypes } from "@sparefoot/segment-react";
import { homePageSelector } from "store/selectors/HomePage";
import { Page } from "components/core/Page";
import { Hero } from "components/brand/Hero";
import {
	ThreeSimpleStepsSection,
	WeMakeStorageEasySection,
	HereForYouSection,
	CustomersSayingSection,
	FeaturedCities
} from "components/landing";

import "./HomePage.scss";

function HomePage() {
	const { trackingPixelUrl, meta } = useSelector(homePageSelector);
	return (
		<Page
			className="home-page"
			title={meta.title}
			pageType={PageTypes.HOME}
			trackingPixelUrl={trackingPixelUrl}
			description={meta.description}
			canonical={meta.canonical}
		>
			<Hero />
			<ThreeSimpleStepsSection />
			<CustomersSayingSection />
			<WeMakeStorageEasySection />
			<HereForYouSection />
			<FeaturedCities />
		</Page>
	);
}

export default HomePage;
