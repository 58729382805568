export const FEATURED_CITIES = [
	{
		text: "Albuquerque, NM",
		url: "https://www.storage.com/self-storage/new-mexico/albuquerque/"
	},
	{
		text: "Asheville, NC",
		url: "https://www.storage.com/self-storage/north-carolina/asheville/"
	},
	{
		text: "Atlanta, GA",
		url: "https://www.storage.com/self-storage/georgia/atlanta/"
	},
	{
		text: "Aurora, CO",
		url: "https://www.storage.com/self-storage/colorado/aurora/"
	},
	{
		text: "Austin, TX",
		url: "https://www.storage.com/self-storage/texas/austin/"
	},
	{
		text: "Bakersfield, CA",
		url: "https://www.storage.com/self-storage/california/bakersfield/"
	},
	{
		text: "Baltimore, MD",
		url: "https://www.storage.com/self-storage/maryland/baltimore/"
	},
	{
		text: "Boise, ID",
		url: "https://www.storage.com/self-storage/idaho/boise/"
	},
	{
		text: "Boston, MA",
		url: "https://www.storage.com/self-storage/massachusetts/boston/"
	},
	{
		text: "Boulder, CO",
		url: "https://www.storage.com/self-storage/colorado/boulder/"
	},
	{
		text: "Bronx, NY",
		url: "https://www.storage.com/self-storage/new-york/bronx/"
	},
	{
		text: "Brooklyn, NY",
		url: "https://www.storage.com/self-storage/new-york/brooklyn/"
	},
	{
		text: "Buffalo, NY",
		url: "https://www.storage.com/self-storage/new-york/buffalo/"
	},
	{
		text: "Charleston, SC",
		url: "https://www.storage.com/self-storage/south-carolina/charleston/"
	},
	{
		text: "Charlotte, NC",
		url: "https://www.storage.com/self-storage/north-carolina/charlotte/"
	},
	{
		text: "Chattanooga, TN",
		url: "https://www.storage.com/self-storage/tennessee/chattanooga/"
	},
	{
		text: "Chicago, IL",
		url: "https://www.storage.com/self-storage/illinois/chicago/"
	},
	{
		text: "Cincinnati, OH",
		url: "https://www.storage.com/self-storage/ohio/cincinnati/"
	},
	{
		text: "Cleveland, OH",
		url: "https://www.storage.com/self-storage/ohio/cleveland/"
	},
	{
		text: "College Station, TX",
		url: "https://www.storage.com/self-storage/texas/college-station/"
	},
	{
		text: "Colorado Springs, CO",
		url: "https://www.storage.com/self-storage/colorado/colorado-springs/"
	},
	{
		text: "Columbus, OH",
		url: "https://www.storage.com/self-storage/ohio/columbus/"
	},
	{
		text: "Dallas, TX",
		url: "https://www.storage.com/self-storage/texas/dallas/"
	},
	{
		text: "Denton, TX",
		url: "https://www.storage.com/self-storage/texas/denton/"
	},
	{
		text: "Denver, CO",
		url: "https://www.storage.com/self-storage/colorado/denver/"
	},
	{
		text: "Des Moines, IA",
		url: "https://www.storage.com/self-storage/iowa/des-moines/"
	},
	{
		text: "Detroit, MI",
		url: "https://www.storage.com/self-storage/michigan/detroit/"
	},
	{
		text: "Durham, NC",
		url: "https://www.storage.com/self-storage/north-carolina/durham/"
	},
	{
		text: "El Paso, TX",
		url: "https://www.storage.com/self-storage/texas/el-paso/"
	},
	{
		text: "Fort Collins, CO",
		url: "https://www.storage.com/self-storage/colorado/fort-collins/"
	},
	{
		text: "Fort Lauderdale, FL",
		url: "https://www.storage.com/self-storage/florida/fort-lauderdale/"
	},
	{
		text: "Fort Myers, FL",
		url: "https://www.storage.com/self-storage/florida/fort-myers/"
	},
	{
		text: "Fort Worth, TX",
		url: "https://www.storage.com/self-storage/texas/fort-worth/"
	},
	{
		text: "Fredericksburg, VA",
		url: "https://www.storage.com/self-storage/virginia/fredericksburg/"
	},
	{
		text: "Gainesville, FL",
		url: "https://www.storage.com/self-storage/florida/gainesville/"
	},
	{
		text: "Glendale, AZ",
		url: "https://www.storage.com/self-storage/arizona/glendale/"
	},
	{
		text: "Greeneville, SC",
		url: "https://www.storage.com/self-storage/south-carolina/greenville/"
	},
	{
		text: "Greensboro, NC",
		url: "https://www.storage.com/self-storage/north-carolina/greensboro/"
	},
	{
		text: "Honolulu, HI",
		url: "https://www.storage.com/self-storage/hawaii/honolulu/"
	},
	{
		text: "Houston, TX",
		url: "https://www.storage.com/self-storage/texas/houston/"
	},
	{
		text: "Indianapolis, IN",
		url: "https://www.storage.com/self-storage/indiana/indianapolis/"
	},
	{
		text: "Jacksonville, FL",
		url: "https://www.storage.com/self-storage/florida/jacksonville/"
	},
	{
		text: "Jersey City, NJ",
		url: "https://www.storage.com/self-storage/new-jersey/jersey-city/"
	},
	{
		text: "Knoxville, TN",
		url: "https://www.storage.com/self-storage/tennessee/knoxville/"
	},
	{
		text: "Lafayette, LA",
		url: "https://www.storage.com/self-storage/louisiana/lafayette/"
	},
	{
		text: "Lakewood, CO",
		url: "https://www.storage.com/self-storage/colorado/lakewood/"
	},
	{
		text: "Las Vegas, NV",
		url: "https://www.storage.com/self-storage/nevada/las-vegas/"
	},
	{
		text: "Los Angeles, CA",
		url: "https://www.storage.com/self-storage/california/los-angeles/"
	},
	{
		text: "Louisville, KY",
		url: "https://www.storage.com/self-storage/kentucky/louisville/"
	},
	{
		text: "Manhattan, NY",
		url: "https://www.storage.com/self-storage/new-york/manhattan/"
	},
	{
		text: "Memphis, TN",
		url: "https://www.storage.com/self-storage/tennessee/memphis/"
	},
	{
		text: "Miami, FL",
		url: "https://www.storage.com/self-storage/florida/miami/"
	},
	{
		text: "Milwaukee, WI",
		url: "https://www.storage.com/self-storage/wisconsin/milwaukee/"
	},
	{
		text: "Minneapolis, MN",
		url: "https://www.storage.com/self-storage/minnesota/minneapolis/"
	},
	{
		text: "Kansas City, MO",
		url: "https://www.storage.com/self-storage/missouri/kansas-city/"
	},
	{
		text: "Naples, FL",
		url: "https://www.storage.com/self-storage/florida/naples/"
	},
	{
		text: "Nashville, TN",
		url: "https://www.storage.com/self-storage/tennessee/nashville/"
	},
	{
		text: "New Orleans, LA",
		url: "https://www.storage.com/self-storage/louisiana/new-orleans/"
	},
	{
		text: "New York, NY",
		url: "https://www.storage.com/self-storage/new-york/new-york/"
	},
	{
		text: "Newark, NJ",
		url: "https://www.storage.com/self-storage/new-jersey/newark/"
	},
	{
		text: "North Las Vegas, NV",
		url: "https://www.storage.com/self-storage/nevada/north-las-vegas/"
	},
	{
		text: "Oakland, CA",
		url: "https://www.storage.com/self-storage/california/oakland/"
	},
	{
		text: "Oklahoma City, OK",
		url: "https://www.storage.com/self-storage/oklahoma/oklahoma-city/"
	},
	{
		text: "Omaha, NE",
		url: "https://www.storage.com/self-storage/nebraska/omaha/"
	},
	{
		text: "Orlando, FL",
		url: "https://www.storage.com/self-storage/florida/orlando/"
	},
	{
		text: "Panama City, FL",
		url: "https://www.storage.com/self-storage/florida/panama-city/"
	},
	{
		text: "Pensacola, FL",
		url: "https://www.storage.com/self-storage/florida/pensacola/"
	},
	{
		text: "Philadelphia, PA",
		url: "https://www.storage.com/self-storage/pennsylvania/philadelphia/"
	},
	{
		text: "Phoenix, AZ",
		url: "https://www.storage.com/self-storage/arizona/phoenix/"
	},
	{
		text: "Pittsburgh, PA",
		url: "https://www.storage.com/self-storage/pennsylvania/pittsburgh/"
	},
	{
		text: "Portland, OR",
		url: "https://www.storage.com/self-storage/oregon/portland/"
	},
	{
		text: "Queens, NY",
		url: "https://www.storage.com/self-storage/new-york/queens/"
	},
	{
		text: "Raleigh, NC",
		url: "https://www.storage.com/self-storage/north-carolina/raleigh/"
	},
	{
		text: "Reno, NV",
		url: "https://www.storage.com/self-storage/nevada/reno/"
	},
	{
		text: "Richmond, VA",
		url: "https://www.storage.com/self-storage/virginia/richmond/"
	},
	{
		text: "Sacramento, CA",
		url: "https://www.storage.com/self-storage/california/sacramento/"
	},
	{
		text: "Salt Lake City, UT",
		url: "https://www.storage.com/self-storage/utah/salt-lake-city/"
	},
	{
		text: "San Antonio, TX",
		url: "https://www.storage.com/self-storage/texas/san-antonio/"
	},
	{
		text: "San Bernardino, CA",
		url: "https://www.storage.com/self-storage/california/san-bernardino/"
	},
	{
		text: "San Diego, CA",
		url: "https://www.storage.com/self-storage/california/san-diego/"
	},
	{
		text: "San Francisco, CA",
		url: "https://www.storage.com/self-storage/california/san-francisco/"
	},
	{
		text: "San Jose, CA",
		url: "https://www.storage.com/self-storage/california/san-jose/"
	},
	{
		text: "Savannah, GA",
		url: "https://www.storage.com/self-storage/georgia/savannah/"
	},
	{
		text: "Scottsdale, AZ",
		url: "https://www.storage.com/self-storage/arizona/scottsdale/"
	},
	{
		text: "Seattle, WA",
		url: "https://www.storage.com/self-storage/washington/seattle/"
	},
	{
		text: "Spokane, WA",
		url: "https://www.storage.com/self-storage/washington/spokane/"
	},
	{
		text: "St. Louis, MO",
		url: "https://www.storage.com/self-storage/missouri/st-louis/"
	},
	{
		text: "Staten Island, NY",
		url: "https://www.storage.com/self-storage/new-york/staten-island/"
	},
	{
		text: "Syracuse, NY",
		url: "https://www.storage.com/self-storage/new-york/syracuse/"
	},
	{
		text: "Tacoma, WA",
		url: "https://www.storage.com/self-storage/washington/tacoma/"
	},
	{
		text: "Tallahassee, FL",
		url: "https://www.storage.com/self-storage/florida/tallahassee/"
	},
	{
		text: "Tampa, FL",
		url: "https://www.storage.com/self-storage/florida/tampa/"
	},
	{
		text: "Tempe, AZ",
		url: "https://www.storage.com/self-storage/arizona/tempe/"
	},
	{
		text: "Toledo, OH",
		url: "https://www.storage.com/self-storage/ohio/toledo/"
	},
	{
		text: "Tucson, AZ",
		url: "https://www.storage.com/self-storage/arizona/tucson/"
	},
	{
		text: "Tulsa, OK",
		url: "https://www.storage.com/self-storage/oklahoma/tulsa/"
	},
	{
		text: "Vancouver, WA",
		url: "https://www.storage.com/self-storage/washington/vancouver/"
	},
	{
		text: "Virginia Beach, VA",
		url: "https://www.storage.com/self-storage/virginia/virginia-beach/"
	},
	{
		text: "Washington, DC",
		url: "https://www.storage.com/self-storage/district-of-columbia/washington/"
	},
	{
		text: "Wichita, KS",
		url: "https://www.storage.com/self-storage/kansas/wichita/"
	}
];