import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Container, Section } from "components/core/Page";
import { PriceTag, MapPinOutline, QuestionSign } from "components/core/Icons";
import { Image } from "@sparefoot/react-commons";
import { Paragraph, H3, H4, Text } from "components/core/Typography";

import NearbyFacilitiesImage from "assets/images/nearby-facilities-graphic.svg";

import "./WeMakeStorageEasySection.scss";

const IconCard = ({ icon, title, body }) => (
	<div className="icon-card">
		{React.cloneElement(icon, { size: "fill", fill: "blue" })}

		<div>
			<H4 size="large">{title}</H4>
			<Paragraph>{body}</Paragraph>
		</div>
	</div>
);

IconCard.propTypes = {
	icon: PropTypes.node,
	title: PropTypes.string.isRequired,
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

/* eslint-disable max-len */

// Just this section's body is a fragment so we can put in this
// span to keep the phone number on one line.
const customerServiceFragment = (
	<Fragment>
		You don&apos;t have to look for storage on your own. Call{" "}
		<Text nowrap>833-448-1067</Text> to have one of our friendly
		representatives help you get the best solution for your needs.
	</Fragment>
);

const cardsContent = [
	{
		icon: <MapPinOutline />,
		title: "Find Storage Units Near You",
		body: "With the ability to search by state all the way down to zip code, you can see the nearest storage units to you."
	},
	{
		icon: <PriceTag />,
		title: "Get the Best Storage Deals",
		body: "If a cheap storage unit is what you're after, you can find low prices and see rental promotions and discounts citywide."
	},
	{
		icon: <QuestionSign />,
		title: "Experience Premier Customer Service",
		body: customerServiceFragment
	}
];

const WeMakeStorageEasySection = () => (
	<Section
		flush
		className="we-make-storage-easy"
	>
		<Container>
			<H3
				last
				className="section-title"
				size="xxlarge"
				mediumSize="large"
			>
				We Make Storage Easy
			</H3>
			<div className="image-container">
				<Image
					className="image"
					lazy
					src={NearbyFacilitiesImage}
					alt="A man holding a cellphone with a tooltip indicating nearby facilities"
					width="582"
					height="613"
				/>
			</div>
			<div className="cards">
				{cardsContent.map((card) => (
					<IconCard
						{...card}
						key={card.title}
					/>
				))}
			</div>
		</Container>
	</Section>
);

export default WeMakeStorageEasySection;
