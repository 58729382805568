import React from "react";
import { FEATURED_CITIES } from "config/featuredCitiesList";

import { Container, Section } from "components/core/Page";
import { Link } from "components/core/Link";
import { Image } from "@sparefoot/react-commons";
import { H3, Paragraph, ListItem } from "components/core/Typography";

import FeaturedCitiesSVG from "assets/images/featured-cities.svg";

import "./FeaturedCities.scss";

export default function Footer() {
	return (
		<Section
			flush
			className="featured-cities"
		>
			<Container>
				<div className="into">
					<Image
						className="icon-image"
						lazy
						src={FeaturedCitiesSVG}
						alt="A large map pin with a star in front of buildings"
						width="120"
						height="120"
					/>
					<H3
						size="xxlarge"
						mediumSize="large"
					>
						Featured Cities
					</H3>
					<Paragraph>
						{" "}
						Find storage units in these popular locations:{" "}
					</Paragraph>
				</div>
				<div className="city-list">
					<ul className="list">
						{FEATURED_CITIES.map(({ text, url }) => (
							<ListItem
								className="list-item"
								color="blue"
								weight="semiBold"
								key={text}
								size="small"
							>
								<Link
									underline
									hover
									href={url}
									segmentLabel={text}
								>
									{text}
								</Link>
							</ListItem>
						))}
					</ul>
				</div>
			</Container>
		</Section>
	);
}
