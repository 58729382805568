import React from "react";

import { Container, Section } from "components/core/Page";
import { Image } from "@sparefoot/react-commons";
import { ListItem, H3 } from "components/core/Typography";

import HomeBoxImage from "assets/images/lower-homepage.svg";

import "./HereForYouSection.scss";

/* eslint-disable max-len */
const listItemsContent = [
	{
		body: "Renting self storage isn't just about storing items you don't have room for. It's about the opportunities in front of you. Whether it's moving to a new home or city, getting ready to go to college, starting your own business, or working through a major life change, Storage.com is here to help you find the space you need."
	},
	{
		body: "Our goal is to help you find and rent the storage unit that best fits your needs. That's why we have thousands of storage facilities across the U.S. listed on our shop-and-compare site."
	},
	{
		body: "We also want the storage reservation process to be as painless as possible. All you have to do is choose a unit and your move-in date, and we'll take care of the rest. And if you ever have any questions, our storage representatives are just a phone call away!"
	}
];

const HereForYouSection = () => (
	<Section
		flush
		className="here-for-you"
	>
		<Container>
			<H3
				className="section-title"
				size="xxlarge"
				mediumSize="large"
			>
				Storage.com is Here For You
			</H3>
			<div className="image-container">
				<Image
					lazy
					src={HomeBoxImage}
					alt="A girl holding a green storage tub"
					width="563"
					height="585"
				/>
			</div>
			<ul>
				{listItemsContent.map(({ body }, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<ListItem key={index}>{body}</ListItem>
				))}
			</ul>
		</Container>
	</Section>
);

export default HereForYouSection;
