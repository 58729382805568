/* eslint-disable max-len */
import React from "react";

import { H3 } from "components/core/Typography";
import { Container, Section, Row, Column } from "components/core/Page";
import { TestimonialCard } from "components/brand/TestimonialCard";

import LizHeadshot from "assets/images/testimonial-liz.jpg";
import SydneyHeadshot from "assets/images/testimonial-sydney.jpg";

import "./CustomersSayingSection.scss";

const TestimonialsData = [
	{
		body: "This was my first time renting a storage unit and I was pretty overwhelmed. I had questions about which size I needed and if I would need climate control. The rep answered all of my questions and helped me reserve the right storage unit for me.",
		author: "Liz C.",
		location: "Washington",
		title: "Excellent Customer Service!",
		imageSrc: LizHeadshot
	},
	{
		body: "I had a friendly service rep who helped me with everything I needed. She took the time to find what I had asked for and even helped me find a lower price.",
		author: "Sydney M.",
		location: "Colorado",
		title: "Outstanding!",
		imageSrc: SydneyHeadshot
	}
];

const CustomersSayingSection = () => (
	<Section
		flush
		className="customers-saying"
	>
		<Container>
			<H3
				className="section-title"
				size="xxlarge"
				mediumSize="large"
			>
				See What Storage.com Customers Are Saying
			</H3>
			<Row
				largeGutter="xxlarge"
				gutter="medium"
			>
				{TestimonialsData.map((testimonial, i) => (
					<Column
						large={6}
						medium={6}
						small={12}
						/* eslint-disable-next-line react/no-array-index-key */
						key={i}
					>
						<TestimonialCard {...testimonial} />
					</Column>
				))}
			</Row>
		</Container>
	</Section>
);

export default CustomersSayingSection;
